import {
  ActionButton,
  Breadcrumbs,
  FormDatePicker,
  FormSingleCheckbox,
  FormTextField,
  PageContent,
  Spinner,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack, Typography, styled } from '@mui/material'
import LogoutButton from 'components/SuperAdminLogoutButton'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { SERVICES } from 'services'
import * as yup from 'yup'

const StyledBanner = styled(Box)(({ theme }) => ({
  height: '150px',
  background: `linear-gradient(to right,#1E28F5, #149ECD)`,
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  color: 'white',
  position: 'relative',
  '& .MuiTypography-root': {
    color: 'white',
    fontSize: '20px',
  },
}))

const schema = yup.object().shape({
  clientName: yup.string().required('Client Name is required'),
  enableRevenue: yup.boolean().optional(),
  enablePaymentsRevenueFrom: yup.mixed().when('enableRevenue', {
    is: true,
    then: yup
      .date()
      .typeError('Enable Payments Revenue From is required')
      .required('Enable Payments Revenue From is required'),
  }),
})

export default function TenantUpdate() {
  const { id } = useParams()

  const { data, isLoading } = useQuery(
    ['clients', id],
    () => SERVICES.tenant.fetchClientById(id),
    {
      enabled: !!id,
    },
  )

  if (isLoading) return <Spinner />

  return (
    <>
      <StyledBanner>
        <Typography variant="h1">CampX Tenant Registration</Typography>
        <LogoutButton />
      </StyledBanner>
      <PageContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Breadcrumbs
            links={[
              { name: 'Clients', to: '/clients' },
              {
                name: !!id ? `${data?.name}` : 'New',
                to: null,
              },
            ]}
          />
        </Box>
        <Box maxWidth={'900px'} margin="20px auto">
          <TenantUpdateForm tenantDetails={data} />
        </Box>
      </PageContent>
      <LogoutButton />
    </>
  )
}

function TenantUpdateForm(props: { tenantDetails: any }) {
  const { tenantDetails } = props
  const queryClient = useQueryClient()

  const { control, handleSubmit, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      clientName: tenantDetails?.name,
      enableRevenue: !!tenantDetails?.enablePaymentsRevenueFrom,
      enablePaymentsRevenueFrom: tenantDetails?.enablePaymentsRevenueFrom,
      externalExamPrintingEnabled: tenantDetails?.externalExamPrintingEnabled,
      internalExamPrintingEnabled: tenantDetails?.internalExamPrintingEnabled,
      frappeCustomerName: tenantDetails?.frappeCustomerName,
    },
  })

  const { isLoading, mutate } = useMutation(SERVICES.tenant.updateTenant, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['clients'] })
    },
    onError: () => {
      // eslint-disable-next-line no-console
      console.log('Error')
    },
  })

  const onSubmit = (values) => {
    const enablePaymentsRevenueFrom = values.enablePaymentsRevenueFrom
      ? moment(values.enablePaymentsRevenueFrom).format('YYYY-MM-DD')
      : null

    const body = {
      _id: tenantDetails?._id,
      frappeCustomerName: values.frappeCustomerName,
      enablePaymentsRevenueFrom: values.enableRevenue
        ? enablePaymentsRevenueFrom
        : null,
      externalExamPrintingEnabled: values.externalExamPrintingEnabled,
      internalExamPrintingEnabled: values.internalExamPrintingEnabled,
      env: {
        clientName: values.clientName,
      },
    }

    mutate(body)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} direction="column">
        <Stack spacing={2} direction="column">
          <FormTextField
            label="College Name"
            name="clientName"
            control={control}
            required
          />
          <FormTextField
            label="Frappe Customer Name"
            name="frappeCustomerName"
            control={control}
            required
          />
          <FormSingleCheckbox
            name="enableRevenue"
            control={control}
            label="Enable Payments Revenue"
          />
          {watch('enableRevenue') && (
            <FormDatePicker
              control={control}
              label="Enable Payments Revenue From"
              name="enablePaymentsRevenueFrom"
            />
          )}
          <FormSingleCheckbox
            name="externalExamPrintingEnabled"
            control={control}
            label="External Exam Printing Enabled"
          />
          <FormSingleCheckbox
            name="internalExamPrintingEnabled"
            control={control}
            label="Internal Exam Printing Enabled"
          />
        </Stack>
        <ActionButton
          type="submit"
          loading={isLoading}
          sx={{ alignSelf: 'center' }}
        >
          Update Details
        </ActionButton>
      </Stack>
    </form>
  )
}
